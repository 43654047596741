import React from 'react';

const Error = () => {
    return (
        <div>
            <h2>Error: Path does not exist</h2>

        </div>
    )
}

export default Error;